import { Box, Button, Text } from 'grommet';
import { FormClose } from 'grommet-icons';
import React, { lazy } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { dispatch } from 'use-bus';

import { sidebarCatalog } from '../../sidebars/catalog';
import PlaceholderLoading from '../placeholder-loading';

const ApiErrorCard = lazy(() => import('../api-error-card/api-error-card'));

const AppSidebar = (props) => {
  function toggleSidebar() {
    dispatch({ type: 'sidebar.toggle', open: false }); // close sidebar
  }

  let SidebarContent = null;

  // check if custom react component should be used to render specific card Id
  const sidebar = sidebarCatalog[props.title];
  if (sidebar) {
    //console.log('render react card ' + metadata?.Title);
    SidebarContent = sidebar;
  } else {
    SidebarContent = sidebarCatalog['empty'];
  }

  // scroll page container to top
  // *todo* anylze why scroll position is restored
  const container = document.getElementById('pageContainer');
  if (container) container.scrollTo(0, 0);

  return (
    <Box flex width="medium" background="white" pad="small" elevation="large" style={{ zIndex: '1000' }}>
      <Box direction="row" align="center" as="header" justify="between">
        <Text size="xlarge">{props.title}</Text>
        <Button icon={<FormClose />} onClick={() => toggleSidebar()} />
      </Box>
      <Box flex overflow="auto" pad="xsmall">
        <React.Suspense fallback={<PlaceholderLoading shape="rect" width={160} height={24} />}>
          <ErrorBoundary FallbackComponent={ApiErrorCard}>
            <SidebarContent {...props} />
          </ErrorBoundary>
        </React.Suspense>
      </Box>
      {props.title !== 'Hello' && (
        <Box as="footer" border={{ side: 'top' }} pad="small" justify="end" direction="row" align="center">
          <Button primary label="Save" />
        </Box>
      )}
    </Box>
  );
};

export default AppSidebar;
