import { useMutation } from 'react-query';

import config, { apiUrl, defaultFetchOpts } from '../config';
import { useSessionStore } from '../store';
import { useToken } from './auth';

export const usePlatformMutation = (url, opts) => {
  const session = useSessionStore((state) => state.session);
  const { access_token } = useToken();

  const queryKey = `${url}:${session.UserId}`;
  const queryUrl = `${apiUrl}${url}`;

  defaultFetchOpts.headers['Content-Type'] = 'application/json';

  if (config.useTokenAuth) {
    defaultFetchOpts.headers.Authorization = `Bearer ${access_token}`;
  } else {
    defaultFetchOpts.credentials = 'include';
  }

  const mergedOpts = {
    ...defaultFetchOpts,
    ...opts,
  };

  const result = useMutation(queryKey, async (body) => {
    mergedOpts.body = JSON.stringify(body);
    const response = await fetch(queryUrl, mergedOpts);

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    return response.json();
  });

  return result;
};

export default usePlatformMutation;
