import React, { useEffect, useState } from 'react';

import { Router } from '@reach/router';

import config, { apiUrl, defaultFetchOpts } from '../../config';
import { useToken } from '../../hooks';
import { useSessionStore } from '../../store';

const opts = defaultFetchOpts;

const PrivateRoute = ({ children, ...rest }) => {
  const setSession = useSessionStore((state) => state.setSession);
  const { access_token } = useToken();

  if (config.useTokenAuth) {
    opts.headers['Authorization'] = `Bearer ${access_token}`;
  } else {
    opts.credentials = 'include';
  }

  const [data, setData] = useState(null);

  const getData = async () => {
    const result = await fetch(`${apiUrl}/session/myprofile`, opts);
    const json = await result.json();
    setData(json);
  };

  useEffect(() => {
    if (!data) {
      getData();
      return;
    }

    switch (config.useTokenAuth) {
      case true:
        if (!access_token) return;
        setSession(data.Data);
        break;
      case false:
        if (data.Data?.authenticated) {
          setSession(data.Data);
          return;
        }

        if (config.oidcProvider) {
          let oidcUrl = `${config.hostUrl}/oauth2connector/signin/${config.oidcProvider}?returnUrl=${window.location.href}`;

          if (config.tenantId) {
            oidcUrl += `&tenant=${config.tenantId}`;
          }

          window.location.href = oidcUrl;
          return;
        }

        window.location.href = `${config.hostUrl}/account/login?returnUrl=${encodeURIComponent(window.location.href)}${config.tenantId ? `&tid=${config.tenantId}` : ''}`;
        break;
    }
  }, [access_token, data, setSession]);

  return <Router {...rest}>{children}</Router>;
};

export default PrivateRoute;
