import { Footer, Anchor } from 'grommet';
import React from 'react';
import styled from 'styled-components';

const FooterLink = styled(Anchor)`
  color: #fff;
  font-weight: 300;
  text-decoration: none;
  line-height: 27px;
  font-size: 14px;

  &:hover {
    color: #fff;
  }
`;

const AppFooter = () => {
  return (
    <Footer
      background="#0d274d"
      gap="25px"
      margin={{ top: 'medium' }}
      justify="center"
      pad={{ vertical: '13px', horizontal: '20px' }}
      style={{ boxShadow: '0 2px 5px 0 rgb(0 0 0 / 12%)' }}
    >
      <FooterLink href="https://www.cisco.com/c/en/us/about/contact-cisco.html" target="_blank" label="Contacts" />
      <FooterLink href="https://secure.opinionlab.com/ccc01/o.asp?id=jBjOhqOJ" target="_blank" label="Feedback" />
      <FooterLink href="https://www.cisco.com/c/en/us/about/help.html" target="_blank" label="Help" />
      <FooterLink href="https://www.cisco.com/c/en/us/about/sitemap.html" target="_blank" label="Site Map" />
      <FooterLink href="https://www.cisco.com/c/en/us/about/legal/terms-conditions.html" target="_blank" label="Terms &amp; Conditions" />
      <FooterLink href="https://www.cisco.com/c/en/us/about/legal/privacy-full.html" target="_blank" label="Privacy Statement" />
      <FooterLink href="https://www.cisco.com/c/en/us/about/legal/privacy-full.html#cookies" target="_blank" label="Cookies" />
      <FooterLink href="https://www.cisco.com/c/en/us/about/legal/trademarks.html" target="_blank" label="Trademarks" />
    </Footer>
  );
};

export default AppFooter;
