import { Box, Grommet, Layer } from 'grommet';
import React, { Suspense, lazy, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import useBus, { dispatch } from 'use-bus';

import { useLocation } from '@reach/router';

import AppFooter from '../components/app-footer/app-footer';
import AppHeader from '../components/app-header';
import AppHeaderSkeleton from '../components/app-header-skeleton';
import AppSidebar from '../components/app-sidebar/app-sidebar';
import PrivateRoute from '../components/private-route/private-route';
import Splash from '../components/splash-screen/splash-screen';
import { AuthContext, Authenticated } from '../hooks';
import { useSessionStore, useSidebarOpen } from '../store';
import theme from '../theme';
import { isSSR } from '../utils';

const SideBar = lazy(() => import('../components/side-bar'));
const Profile = lazy(() => import('../views/assistant/profile/profile'));
const Board = lazy(() => import('../views/assistant/board/board'));
const MyCards = lazy(() => import('../views/assistant/my-cards/my-cards'));
const Page1 = lazy(() => import('../views/admin/page1/page1'));
const Page2 = lazy(() => import('../views/admin/page2/page2'));
const MyConnectors = lazy(() => import('../views/assistant/my-connectors/my-connectors'));
const ChangePassword = lazy(() => import('../views/assistant/change-password/change-password'));

const App = () => {
  const [loading, setLoading] = useState(true);
  const session = useSessionStore((state) => state.session);

  const sidebarOpen = useSidebarOpen((state) => state.sidebarOpen);
  const setSidebarOpen = useSidebarOpen((state) => state.setSidebarOpen);

  const [sidebarConfig] = useState({});

  const location = useLocation();

  useEffect(() => {
    dispatch('overlays.close');
  }, [location.pathname]);

  useEffect(() => {
    if (session?.authenticated) {
      setLoading(false);
    }
  }, [session?.authenticated]);

  function changeSidebar(event) {
    let sbOpen = !sidebarOpen; // default: toggle current state
    if (event.open != undefined) sbOpen = event.open == true; // set specific state when provided in event
    sidebarConfig.id = event.id;
    sidebarConfig.title = event.title;
    sidebarConfig.settings = event.settings;
    setSidebarOpen(sbOpen);
  }

  useBus('sidebar.toggle', (event) => changeSidebar(event), [sidebarOpen]);

  return (
    <AuthContext>
      <Grommet full theme={theme} id="pageContainer">
        <Helmet>
          <meta charSet="utf-8" />
          <title>Bridge</title>
        </Helmet>
        <Box fill="horizontal">
          {loading ? <AppHeaderSkeleton /> : <AppHeader />}
          <Box flex direction="row" fill>
            {!isSSR && (
              <Suspense fallback={<Splash />}>
                <Authenticated>
                  <PrivateRoute>
                    <SideBar path="app/admin/*" />
                  </PrivateRoute>
                </Authenticated>
              </Suspense>
            )}
            <Box fill="horizontal">
              {!isSSR && (
                <Suspense fallback={<Splash />}>
                  <Authenticated>
                    <PrivateRoute>
                      <Profile path="app/assistant/profile" />
                      <Board path="app/assistant/board" />
                      <MyCards path="app/assistant/mycards" />
                      <MyConnectors path="app/assistant/myconnectors" />
                      <Page1 path="app/admin/page1" />
                      <Page2 path="app/admin/page2" />
                      <ChangePassword path="app/assistant/changepassword" />
                    </PrivateRoute>
                  </Authenticated>
                </Suspense>
              )}
            </Box>
            {sidebarOpen && (
              <Layer animate modal full="vertical" position="right" responsive onClickOutside={() => dispatch({ type: 'sidebar.toggle', open: false })}>
                <AppSidebar {...sidebarConfig}></AppSidebar>
              </Layer>
            )}
          </Box>
          <AppFooter></AppFooter>
        </Box>
      </Grommet>
      <Authenticated>
        <div path="app/callback" />
      </Authenticated>
    </AuthContext>
  );
};

export default App;
