import React from 'react';

const Empty = React.lazy(() => import(/* webpackChunkName: "Sidebars" */ './empty.jsx'));
const Alerts = React.lazy(() => import(/* webpackChunkName: "Sidebars" */ './alerts.jsx'));
const Poc = React.lazy(() => import(/* webpackChunkName: "Sidebars" */ './poc.jsx'));
const Hello = React.lazy(() => import(/* webpackChunkName: "Sidebars" */ './Hello'));

const catalog = {};

catalog['empty'] = Empty;
catalog['Alerts'] = Alerts;
catalog['UTC Time'] = Poc;
catalog['Open Leads'] = Poc;
catalog['Hello'] = Hello;

export const sidebarCatalog = catalog;
