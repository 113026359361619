import create from 'zustand';

export const useSessionStore = create((set) => ({
  session: {},
  setSession: (data) => {
    set({ session: data });
  },
}));

export const useSidebarOpen = create((set) => ({
  sidebarOpen: false,
  setSidebarOpen: (open) => {
    set({ sidebarOpen: open });
  },
}));
