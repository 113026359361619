import './splash.css';

import React from 'react';

const Splash = () => {
  return (
    <div className="splash-container">
      <svg xmlns="http://www.w3.org/2000/svg" width="108" height="57" viewBox="0 0 108 57" role="img" className="splash">
        <g fill="#1BA0D7" fillRule="evenodd">
          <path d="M0 22.769a2.348 2.348 0 0 0 3.935 1.545c.434-.403.7-.955.744-1.545v-5.2a2.34 2.34 0 0 0-4.679 0v5.2" id="bar_1" className="bar"></path>
          <path d="M12.95 22.769a2.349 2.349 0 0 0 2.34 2.171 2.346 2.346 0 0 0 2.339-2.171V11.112a2.341 2.341 0 0 0-4.679 0V22.77" id="bar_2" className="bar"></path>
          <path d="M25.832 27.464a2.345 2.345 0 0 0 4.678 0V2.249a2.342 2.342 0 0 0-4.678 0v25.215" id="bar_3" className="bar"></path>
          <path d="M38.78 22.769a2.346 2.346 0 0 0 4.68 0V11.112a2.344 2.344 0 0 0-2.34-2.249 2.341 2.341 0 0 0-2.34 2.25v11.656" id="bar_4" className="bar"></path>
          <path
            d="M51.671 22.769a2.348 2.348 0 0 0 3.936 1.545c.434-.403.7-.955.743-1.545v-5.2a2.343 2.343 0 0 0-3.962-1.595c-.436.419-.693.99-.717 1.595v5.2"
            id="bar_5"
            className="bar"
          ></path>
          <path d="M64.61 22.769a2.347 2.347 0 0 0 4.679 0V11.112a2.342 2.342 0 0 0-4.679 0V22.77" id="bar_6" className="bar"></path>
          <path d="M77.496 27.464a2.345 2.345 0 0 0 4.678 0V2.249A2.342 2.342 0 0 0 78.212.653a2.34 2.34 0 0 0-.716 1.596v25.215" id="bar_7" className="bar"></path>
          <path d="M90.424 22.769a2.346 2.346 0 0 0 4.679 0V11.112a2.343 2.343 0 0 0-2.34-2.249 2.342 2.342 0 0 0-2.339 2.25v11.656" id="bar_8" className="bar"></path>
          <path d="M103.321 22.769a2.346 2.346 0 0 0 4.68 0v-5.2a2.343 2.343 0 0 0-2.34-2.249 2.341 2.341 0 0 0-2.34 2.25v5.199" id="bar_9" className="bar"></path>
        </g>
      </svg>
    </div>
  );
};

export default Splash;
