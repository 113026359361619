import { useQuery } from 'react-query';

import config, { apiUrl, defaultFetchOpts } from '../config';
import { useSessionStore } from '../store';
import { useToken } from './auth';

export const usePlatformQuery = (url) => {
  const session = useSessionStore((state) => state.session);
  const { access_token } = useToken();

  const queryKey = `${url}:${session.UserId}`;
  const queryUrl = `${apiUrl}${url}`;

  if (config.useTokenAuth) {
    defaultFetchOpts.headers.Authorization = `Bearer ${access_token}`;
  } else {
    defaultFetchOpts.credentials = 'include';
  }

  const result = useQuery(
    queryKey,
    async () => {
      const response = await fetch(queryUrl, defaultFetchOpts);

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      return response.json();
    },
    {
      enabled: !!session,
      staleTime: 300000,
      refetchInterval: 300000,
      refetchOnWindowFocus: true,
    },
  );

  return result;
};

export default usePlatformQuery;
