import { Anchor, Avatar, Box, Heading, Image, Text, Tip } from 'grommet';
import { Action, AddCircle, Link as LinkIcon, SettingsOption } from 'grommet-icons';
import React from 'react';

import { Link } from '@reach/router';

import { hostUrl } from '../config';
import { useSessionStore } from '../store';

const AccountPanel = () => {
  const session = useSessionStore((state) => state.session);

  if (!session?.authenticated) return null;

  return (
    <Box align="stretch" elevation="xlarge" direction="column" alignSelf="start" justify="start" background={{ color: '#fff' }} width="375px">
      <Box align="center" justify="start" direction="row" alignSelf="start" fill="horizontal" pad={{ horizontal: 'medium', vertical: 'small' }}>
        <Box align="start" justify="center" flex="grow" fill="vertical">
          <Text textAlign="center">{session.companyName}</Text>
        </Box>
        <Box align="end" justify="center" fill="vertical">
          <Anchor textAlign="center" color="blue" weight="bold" href={`${session.services.APIHost}/account/logout?returnUrl=${window.location.href}`}>
            Log out
          </Anchor>
        </Box>
      </Box>
      <Box align="center" justify="start" pad={{ horizontal: 'medium', vertical: 'small' }} direction="row" fill="horizontal">
        <Box align="center" justify="center">
          <Avatar align="center" flex={false} justify="center" overflow="hidden" round="full" src={session.avatarUrl} size="large" />
        </Box>
        <Box align="start" justify="center" gap="none" pad={{ left: 'small' }}>
          <Box align="start" direction="column">
            <Heading level="5" size="small" textAlign="start" margin={{ vertical: 'none', right: 'xsmall' }}>
              {session.UserName}
            </Heading>
            {session.isOIDC ? (
              <Tip content={<Box>Your account is managed by {session.oidcProvider}</Box>}>
                <LinkIcon size="small"></LinkIcon>
              </Tip>
            ) : (
              <Link to="/app/assistant/changepassword">
                <Text size="small" color="blue">
                  Change password
                </Text>
              </Link>
            )}
          </Box>
          <Text textAlign="center">{session.UserEmail}</Text>
        </Box>
      </Box>
      <Box align="start" justify="center" fill margin={{ vertical: 'small' }} pad={{ horizontal: 'medium', vertical: 'small' }} gap="small">
        <Link to="/app/assistant/mycards">
          <Box align="center" justify="start" direction="row">
            <AddCircle color="blue" />
            <Text margin={{ left: 'xsmall' }} color="blue">
              Manage Cards
            </Text>
          </Box>
        </Link>
        <Link to="/app/assistant/myconnectors">
          <Box align="center" justify="start" direction="row">
            <Action color="blue" />
            <Text margin={{ left: 'xsmall' }} color="blue">
              Linked accounts
            </Text>
          </Box>
        </Link>
        {session.Roles.includes('Administrator') && (
          <Anchor href={`${hostUrl}/admin/getstarted`} target="_blank" style={{ fontWeight: 400, textDecoration: 'none' }}>
            <Box align="center" justify="start" direction="row">
              <SettingsOption color="blue" />
              <Text margin={{ left: 'xsmall' }} color="blue">
                Administration
              </Text>
            </Box>
          </Anchor>
        )}
      </Box>
      <Box align="start" justify="center" margin="xsmall">
        <Box align="start" justify="center" background={{ color: 'light-2' }} fill="horizontal" pad="xsmall">
          <Text textAlign="start" size="xsmall" weight="bold" color="dark-2">
            ADD YOUR ASSISTANT TO...
          </Text>
        </Box>
        <Box align="baseline" justify="start" background={{ color: 'light-1' }} direction="row-responsive" flex="grow" fill="horizontal" gap="small" pad="small">
          {session.channels.map((channel) => {
            return (
              <Anchor
                style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', textAlign: 'center' }}
                hoverIndicator
                gap="xxsmall"
                width="xsmall"
                href={channel.link}
                key={channel.link}
                target="_blank"
              >
                <Box
                  align="center"
                  justify="center"
                  background={{ color: 'white' }}
                  round="small"
                  elevation="xsmall"
                  width="xxsmall"
                  height="xxsmall"
                  pad="xsmall"
                  border={{ color: 'active', size: 'xsmall' }}
                >
                  <Image src={channel.icon} fit="contain" />
                </Box>
                <Text size="xsmall">{channel.title}</Text>
              </Anchor>
            );
          })}
        </Box>
      </Box>
    </Box>
  );
};

export default AccountPanel;
