import React, { createContext, useCallback, useContext, useEffect } from 'react';

import { navigate } from '@reach/router';

import config from '../../config';
import authorize from './helpers/authorize';
import { fetchToken } from './helpers/fetchToken';
import { getCodeFromLocation } from './helpers/getCodeFromLocation';
import { getVerifierState } from './helpers/getVerifierState';
import { removeCodeFromLocation } from './helpers/removeCodeFromLocation';
import { removeStateFromStorage } from './helpers/removeStateFromStorage';

export default ({
  clientId,
  clientSecret,
  provider,
  scopes = [],
  tokenEndpoint = `${provider}/token`,
  storage = typeof window !== 'undefined' ? sessionStorage : undefined,
  fetch = typeof window !== 'undefined' ? window.fetch : undefined,
  busyIndicator = <></>,
}) => {
  const context = createContext({});
  const { Provider } = context;

  class Authenticated extends React.Component {
    static contextType = context;
    componentDidMount() {
      const { ensureAuthenticated } = this.context;
      ensureAuthenticated();
    }
    render() {
      const { token } = this.context;
      const { children } = this.props;

      if (config.useTokenAuth && !token) {
        return busyIndicator;
      } else {
        return children;
      }
    }
  }

  const useToken = () => {
    const { token } = useContext(context);

    if (!config.useTokenAuth) {
      return { access_token: null };
    }

    if (!token) {
      console.warn(`Trying to useToken() while not being authenticated.\nMake sure to useToken() only inside of an <Authenticated /> component.`);
    }
    return token;
  };

  return {
    AuthContext: ({ children }) => {
      const _token = storage?.getItem('pkce_token');

      const token = _token ? JSON.parse(_token) : null;
      const setToken = useCallback((newToken) => storage?.setItem('pkce_token', JSON.stringify(newToken)), []);

      // if we have no token, but code and verifier are present,
      // then we try to swap code for token
      useEffect(() => {
        if (config.useTokenAuth && !token) {
          const code = getCodeFromLocation({ location: window.location });
          const state = getVerifierState({ clientId, storage });
          if (code && state) {
            fetchToken({
              clientId,
              clientSecret,
              tokenEndpoint,
              code,
              state,
              fetch,
            })
              .then(setToken)
              .then(() => {
                removeCodeFromLocation();
                removeStateFromStorage({ clientId, storage });
                const params = new URLSearchParams(window.location.search);
                if (params.has('returnUrl')) {
                  navigate(params.get('returnUrl'));
                }
              })
              .catch((e) => {
                console.error(e);
                alert(`Error fetching auth token: ${e.message}`);
              });
          }
        }
      }, [setToken, token]);

      const ensureAuthenticated = () => {
        if (!config.useTokenAuth) return;
        const code = getCodeFromLocation({ location: window.location });
        if (!token && !code) {
          authorize({ provider, clientId, scopes });
        }
      };

      return <Provider value={{ token, ensureAuthenticated }}>{children}</Provider>;
    },
    Authenticated,
    useToken,
  };
};
